<script setup>
import { JTab } from "media-flow-ui"
import { ref, computed, onMounted, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useAsyncData } from "#app"

import TabData from "~~/models/layout/tabs/TabData"
import { usePageBuilder } from "~/stores/page-builder"
import { JUSTIFY_CONTENT_OPTIONS } from "~~/constants/style"

import { formatIconStyle } from "~/helpers/icon/icon"
import { formatComponentStyle } from "@/models/layout/style/component"

// Initialize the page builder store
const pageBuilderStore = usePageBuilder()

// Define component props
const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: null,
  },
  rowOrder: {
    type: Number,
    required: false,
    default: null,
  },
})

// Initialize Vue Router and Route
const route = useRoute()
const router = useRouter()

// Define reactive state variables
const tabsData = ref([])
const tabActiveIndex = ref(0)

// Define computed properties for styles and settings
const isChangeOnHover = computed(() => props.data?.changeOnHover)
const allowToSaveTabOnUrl = computed(
  () => props.data?.props?.allowToSaveTabOnUrl || false
)

const style = computed(() =>
  formatComponentStyle({
    alignment: props.data?.style?.alignment,
    gap: props.data?.style?.gap,
    width: props.data?.style?.width,
  })
)

const tabItemStyle = computed(() =>
  formatComponentStyle({
    ...props.data?.style,
    borderProperties: {
      ...props.data?.style?.borderProperties,
      show: true,
    },
    width: null,
    gap: null,
    borderStyle: "solid",
    textAlign: props.data?.style?.alignment || "left",
  })
)

const iconsStyle = computed(() => formatIconStyle(props.data?.icon))

const wrapperClasses = computed(
  () =>
    `${JUSTIFY_CONTENT_OPTIONS[props.data?.style?.alignment] || "justify-left"}`
)

// Asynchronous function to fetch tab data
const fetchTabsData = async () => {
  try {
    const promises = (props.data?.tabs || []).map(async (tab, tabsIndex) => {
      if (tab.isDynamic) {
        const queryId = tab.settings?.query?.id
        const params = { take: tab.settings?.take || tab.settings?.limit }

        let response
        try {
          const res = await pageBuilderStore.actionGetQueryDataInitClient(
            queryId,
            params,
            null,
            null,
            props.rowOrder
          )
          response = res?.response
        } catch (error) {
          console.error("Error fetching dynamic tab data:", error)
        }

        if (!response) return []

        return response.data.map((rawTab, tabIndex) => {
          const tabData = new TabData()
          tabData.setTabLayout(tab.layout[0])
          tabData.setTabName(rawTab.title)
          tabData.setTabContentId(rawTab.contentId)
          tabData.setTabManagement(tabsIndex, tabIndex)
          return tabData
        })
      } else {
        // Handle static tabs
        const tabData = new TabData()
        tabData.setTabName(tab.data.name)
        tabData.setTabLinkData(tab.data.linkData, props.data?.changeOnHover)
        tabData.setTabLayout(tab.layout[0])
        return [tabData]
      }
    })

    const results = await Promise.all(promises)
    tabsData.value = results.flat()
    initializeActiveTab() // Initialize after fetching
  } catch (error) {
    console.error("Error fetching tabs data:", error)
  }
}

// Fetch data using useAsyncData for SSR
const { data: fetchedTabsData, error } = useAsyncData(async () => {
  await fetchTabsData()
})

// Handle errors during SSR fetching
if (error.value) {
  console.error("Error loading tabs data on server:", error.value)
}

// Assign fetched data to tabsData
tabsData.value = fetchedTabsData.value || []

// Initialize the active tab based on route query or default to the first tab
const initializeActiveTab = () => {
  const tabNameByQueryUrl = route.query.tab || null
  let indexFound = tabsData.value.findIndex(
    (tab) => tab.name === tabNameByQueryUrl
  )

  if (indexFound === -1) indexFound = 0

  tabActiveIndex.value = indexFound
  tabsData.value.forEach((tab, index) => {
    tab.loaded = index === indexFound
  })
}

// Call initializeActiveTab after mounting
// onMounted(() => {

//   initializeActiveTab()
// })

// Handle tab click
const tabClick = ({ tabName, tabIndex }) => {
  if (!tabsData.value[tabIndex].loaded) {
    tabsData.value[tabIndex].loaded = true
  }
  tabActiveIndex.value = tabIndex

  if (!isChangeOnHover.value && allowToSaveTabOnUrl.value) {
    router.replace({ query: { tab: tabName } })
  }
}

//grid grid-cols-1
// grid-cols-1
// grid-cols-2
// grid-cols-3
// grid-cols-4
// grid-cols-5
// grid-cols-6
// grid-cols-7
// grid-cols-8
// grid-cols-9
// grid-cols-10

// md:grid-cols-1
// md:grid-cols-2
// md:grid-cols-3
// md:grid-cols-4
// md:grid-cols-5
// md:grid-cols-6
// md:grid-cols-7
// md:grid-cols-8
// md:grid-cols-9
// md:grid-cols-10
</script>

<template>
  <div>
    <!-- Dynamic Tab Rendering -->

    <JTab
      :key="tabActiveIndex"
      :tabs="tabsData"
      :onTabClickCallback="tabClick"
      :activeTab="tabActiveIndex"
      :change-on-hover="isChangeOnHover"
      :wrapper-classes="wrapperClasses"
      :tab-item-style="tabItemStyle"
      :layout-type="data.props.layoutType"
      :is-vertical="data.props.isVertical"
      :is-carousel="data.props.isCarousel"
      :wrapper-style="style"
      :icons-style="iconsStyle"
    >
      <template #tab="data">
        <Widget v-if="data.tab.loaded" :data="data.tab" :rowOrder="rowOrder" />
      </template>
    </JTab>
  </div>
</template>

<style>
/* Add any custom styles here */
</style>
